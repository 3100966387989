import { Link } from "react-router-dom"
import classNames from "classnames"

interface CardProps {
  boxShadow?: "none" | "medium"
  children: React.ReactNode
  marginBottom?: "none" | "small" | "medium"
  overflow?: "visible" | "hidden"
  padding?: "none" | "medium" | "large" | "mediumLarge"
  type?: "default" | "warning" | "error" | "brand" | "brandOutlined"
}

interface CardRowProps {
  background?: "default" | "success" | "brand" | "error"
  borderBottom?: boolean
  children: React.ReactNode
  size?: "default" | "medium"
}

interface CardImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  openInNewWindow?: boolean
  to?: string
}

const cardBoxShadowMediumClasses = classNames(`shadow-default dark:shadow-default-light`)
const cardClasses = classNames(`relative flex flex-col rounded-xl border`)
const cardImageClasses = classNames(`h-36 w-full max-w-full scale-100 object-cover transition-transform duration-500 ease-in-out hover:scale-105 sm:h-40`)
const cardLinkClasses = classNames(`block overflow-hidden`)
const cardMarginBottomSmallClasses = classNames(`mb-4 sm:mb-8 xl:mb-10`)
const cardMarginBottomMediumClasses = classNames(`mb-10 md:mb-16`)
const cardOverflowHiddenClasses = classNames(`overflow-hidden`)
const cardPaddingLargeClasses = classNames(`p-4 md:p-8`)
const cardPaddingMediumClasses = classNames(`p-4`)
const cardPaddingMediumLargeClasses = classNames(`p-4 md:px-8`)
const cardRowBorderClasses = classNames(`border-b border-gray-100 dark:border-gray-100/10`)
const cardRowBrandClasses = classNames(`border-none bg-brand-primary text-white dark:bg-brand-dark-primary`)
const cardRowClasses = classNames(`flex flex-col justify-center px-4 py-2`)
const cardRowSizeBigClasses = classNames(`min-h-16`)
const cardRowSuccesClasses = classNames(`border-none bg-green-700 text-white`)
const cardTypeBrandClasses = classNames(`border border-brand-primary bg-brand-primary text-white dark:border-brand-dark-primary dark:bg-brand-dark-primary`)
const cardTypeBrandOutlinedClasses = classNames(`border border-brand-primary dark:border-brand-dark-primary`)
const cardTypeDefaultClasses = classNames(`border border-gray-100 bg-white dark:border-2 dark:border-gray-100/10 dark:bg-transparent`)
const cardTypeErrorClasses = classNames(`border border-red-600 bg-red-600 text-white`)
const cardTypeWarningClasses = classNames(`border border-red-600 dark:border-red-600/70 dark:bg-transparent`)

export function Card({ boxShadow = "medium", children, padding, overflow = "visible", type = "default", marginBottom }: CardProps) {
  return (
    <div
      className={classNames(
        cardClasses,
        boxShadow === "medium" && cardBoxShadowMediumClasses,
        marginBottom === "small" && cardMarginBottomSmallClasses,
        marginBottom === "medium" && cardMarginBottomMediumClasses,
        overflow === "hidden" && cardOverflowHiddenClasses,
        padding === "large" && cardPaddingLargeClasses,
        padding === "medium" && cardPaddingMediumClasses,
        padding === "mediumLarge" && cardPaddingMediumLargeClasses,
        type === "brand" && cardTypeBrandClasses,
        type === "brandOutlined" && cardTypeBrandOutlinedClasses,
        type === "default" && cardTypeDefaultClasses,
        type === "error" && cardTypeErrorClasses,
        type === "warning" && cardTypeWarningClasses
      )}
    >
      {children}
    </div>
  )
}

export function CardRow({ borderBottom = false, background = "default", children, size = "default" }: CardRowProps) {
  return (
    <div
      className={classNames(
        cardRowClasses,
        background === "brand" && cardRowBrandClasses,
        background === "success" && cardRowSuccesClasses,
        background === "error" && cardTypeErrorClasses,
        borderBottom && cardRowBorderClasses,
        size === "medium" && cardRowSizeBigClasses
      )}
    >
      {children}
    </div>
  )
}

export function CardImage({ openInNewWindow = false, to, ...rest }: CardImageProps) {
  if (!!to) {
    return (
      <Link className={cardLinkClasses} to={to} rel={openInNewWindow ? "noopener noreferrer" : ""} target={openInNewWindow ? "_blank" : "_self"}>
        <img {...rest} height="160" width="700" className={cardImageClasses} />
      </Link>
    )
  }
  return (
    <div>
      <img {...rest} className={cardImageClasses} />
    </div>
  )
}
